<template>
  <ul class="folder-contents">
    <li v-for="directory in directories" :key="directory.mediaDirectoryID">
     
     <div class="folder-row" >
      <MediaTreeFolder v-if="directory.directories" :directory="directory" @selectDirectory="selectDirectory($event)"/>
      <span v-else>
        <div  @click="openDirectory(directory.mediaDirectoryID)" >
            <v-icon v-if="isOpened(directory.mediaDirectoryID) === true" right>arrow_drop_down</v-icon>
            <v-icon v-else right>arrow_right</v-icon>
          </div>
          <div @click="selectDirectory(directory.mediaDirectoryID)">
            <v-icon right>folder</v-icon>&nbsp;{{directory.directoryName}}
          </div>
        </span>
      </div>
    </li>
  </ul>
</template>
<script>
export default {
  name: "MediaTreeFolderContents",
  
  components: {
    MediaTreeFolder: () => import("@/components/Media/MediaTreeFolder.vue")
  } ,

  props: {
    directories: {
      type: Array,
      required: true
    }
  },

  data: () => ({
   
  }),

  mounted() {
    
  },

  methods: {
    selectDirectory(mediaDirectoryID) {
      console.log("selectDirectory1", mediaDirectoryID)
      this.$emit("selectDirectory", mediaDirectoryID)
    },

    isOpened(mediaDirectoryID){
      // load opened states
      if(localStorage.mediaLibraryOpened){
        var openedItems =  localStorage.mediaLibraryOpened.split(",")
        if(openedItems.includes(mediaDirectoryID)) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    openDirectory(mediaDirectoryID){
      console.log("open directory", mediaDirectoryID)
      var mediaLibraryOpened = localStorage.mediaLibraryOpened.split(",")
      if(mediaLibraryOpened.includes(mediaDirectoryID)){
        mediaLibraryOpened = mediaLibraryOpened.filter(function(value, index, arr){
            console.log(index, arr)
            return value != mediaDirectoryID;

        });
        this.isOpened = false
        localStorage.mediaLibraryOpened = mediaLibraryOpened
      } else {
        mediaLibraryOpened.push(mediaDirectoryID)
        localStorage.mediaLibraryOpened = mediaLibraryOpened
        this.isOpened = true
      }
    },
    setCurrentDirectory(mediaDirectoryID){
      this.$store.commit("saveCurrentMediaDirectory", mediaDirectoryID)
    }

  }
}
</script>

<style scoped lang="scss">
  .folder-contents {
    padding: 0;
  }

  .folder-icons{
    display: inline;
  }
  .folder-row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    cursor: pointer;
  }
  .folder-row ul {
      width: 100%;
  }
  .v-icon--right {
    margin-left: 1px; 
  
  }
</style>